import * as App from './App';
import * as ApiInterface from './ApiInterface2';
import * as FetchUsers from './FetchUsers';
import * as Reports from './Reports';
import * as FetchTransportRequests from './FetchTransportRequests';


// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    App: App.default,
    ApiInterface: ApiInterface.default,
    fetchUsers: FetchUsers.default,
    reports: Reports.default,
    fetchTransportRequests: FetchTransportRequests.default,
};

