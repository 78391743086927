import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

import { Feature, FeatureCollection, GeoJsonProperties, Geometry, Polygon, Position } from 'geojson';


import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import Cookies from 'universal-cookie';
import * as Models from "../models/Models";
import * as utils from '../store/Utils';
import { useDeleteCompanyDeliveryZoneMutation, useDeleteCompanyDeliveryZoneUserMutation, useGetCompanyDeliveryZonesQuery, useGetCompanyDeliveryZoneUsersQuery, useGetListsUsersByCompanyQuery, useSaveCompanyDeliveryZoneMutation, useSaveCompanyDeliveryZoneUserMutation } from '../store/apiSlice';
import { Box, IconButton, Input, Link, MenuItem, Paper, Select, SelectChangeEvent, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';


import {
  EditNoteOutlined as EditNoteOutlinedIcon,
  SaveOutlined as SaveOutlinedIcon,
  DeleteForeverOutlined as DeleteForeverOutlinedIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  ArrowRightOutlined as ArrowRightOutlinedIcon
} from '@mui/icons-material';
import { useAppDispatch } from '../store/configureStore';
import { setError } from '../store/ApiInterface2';




type ZoneTableItem = Models.CompanyDeliveryZone & {is_edit: boolean};

const Zones = () => {
  const dispatch = useAppDispatch();
  
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<{ value: mapboxgl.Map | null }>({ value: null });
  const drawRef = useRef<{ value: MapboxDraw | null }>({ value: null });
  const [mapInitialized, setMapInitialized] = useState(false);
  const [selectedZoneId, setSelectedZoneId] = useState<number>(0);
  const [selectedZoneName, setSelectedZoneName] = useState<string>('');
  const [selectedMode, setSelectedMode] = useState<string>("");
  const [zonesTable, setZonesTable] = useState<ZoneTableItem[]>();
  const [zoneName, setZoneName] = useState<string>('');
  const [forceUpdateFeatures, setForceUpdateFeatures] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number>(0);
  

  //nu avem company_id ca parametru in URL si il luam in SQL de pe user
  const { data : zones } = useGetCompanyDeliveryZonesQuery(0);
  const [ updateZone ] = useSaveCompanyDeliveryZoneMutation();
  const [ deleteZone ] = useDeleteCompanyDeliveryZoneMutation();
  const { data: users_list } = useGetListsUsersByCompanyQuery(0)

  const { data : selected_zone_users } = useGetCompanyDeliveryZoneUsersQuery({ company_id: 0, zone_id: selectedZoneId});
  const [ saveUser ] = useSaveCompanyDeliveryZoneUserMutation();
  const [ deleteUser ] = useDeleteCompanyDeliveryZoneUserMutation();

  const cookies = new Cookies(null, { path: '/' });
  const last_coordinates = cookies.get('last_coordinates');

  const lng: number = last_coordinates ? last_coordinates.lng : Models.lng_default;
  const lat: number = last_coordinates ? last_coordinates.lat : Models.lat_default;
  const zoom: number = last_coordinates ? last_coordinates.zoom : Models.zoom_default;

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);  

  const handleMapMove = () => {
    if (mapRef.current.value) {

        var obj = {
            lng: mapRef.current.value.getCenter().lng,
            lat: mapRef.current.value.getCenter().lat,
            zoom: mapRef.current.value.getZoom()
        };

        var obj_json = JSON.stringify(obj);
        const cookies = new Cookies();
        cookies.set('last_coordinates', obj_json, { path: '/' });
    }
  }
  
  

  const users_not_in_selected_zone = useMemo(():Models.User[] | undefined =>{

    return users_list?.filter(x => (selected_zone_users?.findIndex(y => y.user_id === x.user_id) === -1))

  }, [selected_zone_users, users_list])

  const handleSelectZone = useCallback((zone_id: number) =>{
    setSelectedZoneId(zone_id);

    const draw = drawRef?.current?.value;
    const data = draw?.getAll();

    if (draw && data){
      for (let i = 0; i < data.features.length; i++) {
        
        const feature = data.features[i];
        const feature_zone_id = feature.properties?.zone_id;
        
        if (feature && feature.id && zone_id === feature_zone_id){          
          //draw.changeMode('simple_select', { featureIds: [ feature.id ] });
          const ids : any = [ feature.id ];          
          const mode = selectedMode || 'simple_select';
          
          if (mode === 'simple_select'){
            draw.changeMode(mode, { featureIds: ids });
          }
          else if (mode === 'direct_select'){
            draw.changeMode(mode, { featureId: feature.id as any });
          }
        }
      }
    }
  }, [selectedMode]);

  const handleSaveZoneName = (zone_id: number) => {
    if (zones){
      const zone = zones.find(x => x.zone_id === zone_id);
      if (zone && zone.name !== zoneName){
        const new_zone = {
          ...zone,
          name: zoneName,
          zones: [...zones]
        };
        updateZone([ new_zone ]);
      }      
    }

    setZoneName('');
    setZonesTable((zonesTable) => (zonesTable?.map(x => ({ ...x, is_edit: false}))));
  }

  const handleEditZoneName = (zone_id: number) => {
    if (zonesTable){
      const new_zones = zonesTable.map((x) => {
        return {
          ...x,
          is_edit: x.zone_id === zone_id
        }
      });

      const zone_name = new_zones.find(x => x.zone_id === zone_id)?.name || '';
      setZonesTable(new_zones);
      setZoneName(zone_name);
    }
  }

  const handleSelectUserId = (e: SelectChangeEvent<number>) => {    
    setSelectedUserId(e.target.value as number);    
  }
  const handleSaveZoneUser = () => {
    if (selectedUserId === 0){
      dispatch(setError("Select the user"))
      return;
    }
    if (selectedZoneId === 0){
      dispatch(setError("Select the zone"))
      return;
    }
    
    saveUser({
      company_id: 0,
      user_id: selectedUserId,
      zone_id: selectedZoneId
    });
    setSelectedUserId(0);
  }

  const handleDeleteZoneUser = (user_id: number, name: string) => {    
    if (!window.confirm("Are you sure you want to delete user: " + name)){      
      return;
    }
    else {
      if (selectedZoneId === 0){
        dispatch(setError("Select the zone"))
        return;
      }
      deleteUser({zone_id: selectedZoneId, user_id: user_id});
    }
  }


  const deleteZoneWrapper = useCallback((zone_id: number, name: string, force_update_features: boolean) => {    
    if (!window.confirm("Are you sure you want to delete zone name: " + name)){
      if (force_update_features){//daca e apelata din Map atunci poligonul este deja sters din harta si o sa trebuiasca sa il aducem din nou
        setForceUpdateFeatures((f) => !f);
      }
      return;
    }
    else {
      deleteZone(zone_id);
    }
  }, [deleteZone])

  const handleDeleteZone = (zone_id: number, name: string) => {    
    deleteZoneWrapper(zone_id, name, false);
  }

  useEffect(() => {
    const name = zones?.find((x) => x.zone_id === selectedZoneId)?.name || '';
    setSelectedZoneName(name);
  }, [selectedZoneId, zones])

  const deleteArea = useCallback((e: MapboxDraw.DrawDeleteEvent) => {
    
    for (let i = 0; i < e.features.length; i++) {
      const feature = e.features[i];
      const zone_id = feature.properties?.zone_id;
      const name = feature.properties?.name;
      deleteZoneWrapper(zone_id, name, true);
    }
    setSelectedZoneId(0);

  }, [deleteZoneWrapper])

  const selectArea = useCallback((e: MapboxDraw.DrawSelectionChangeEvent) => {
    
    const mode = drawRef?.current?.value?.getMode();
    setSelectedMode(mode || '');

    let selection_found = false;

    for (let i = 0; i < e.features.length; i++) {
      const feature = e.features[i];
      const zone_id = feature.properties?.zone_id || 0;
      setSelectedZoneId(zone_id);
      selection_found = true;
    }

    if (!selection_found){
      setSelectedZoneId(0);
    }
  }, [])


  

  const updateArea = useCallback((e: MapboxDraw.DrawCreateEvent | MapboxDraw.DrawUpdateEvent) => {
    const data = drawRef?.current?.value?.getAll();

    if (data && data.features.length > 0) {

      let zones: Models.CompanyDeliveryZone[] = [];

      for (let i = 0; i < data.features.length; i++) {

        const feature = data.features[i];

        const poly = feature.geometry as Polygon;
        if (poly.coordinates && poly.coordinates.length > 0)
        {

          const zone_points = poly.coordinates[0].map((x) => {
            const point : Models.LatLng = {
              lng: x[0],
              lat: x[1]
            };
            return point;                
          });

          const zone: Models.CompanyDeliveryZone = {
            zone_id: feature.properties?.zone_id || 0,
            company_id: 0,
            name: feature.properties?.name || '',
            zone: zone_points
          };
          zones.push(zone);
        }            
      }
      updateZone(zones);
    }
  }, [updateZone])

  
  useEffect(() => {

    var textFeatures: Array<Feature<Geometry>> = [];
    const source = mapRef?.current?.value?.getSource('polygon-text') as GeoJSONSource;

    //executam cand harta este initializata; altfel se intapla ca source sa fie null si nu incarca lable-urile
    if (mapInitialized && zones && zones.length > 0 && drawRef.current.value){

      drawRef.current.value.deleteAll();

      for (let i = 0; i < zones.length; i++) {

        const zone = zones[i];

        const positions = zone.zone.map((x) => {
          const position : Position = [ x.lng, x.lat ];
          return position;          
        })

        const geometry: Polygon = {
          type: 'Polygon',
          coordinates: [ positions ]
        }

        const props: GeoJsonProperties = {
          name: zone.name,
          zone_id: zone.zone_id,
        }


        const feature: Feature<Polygon> = {
          type: 'Feature',
          geometry: geometry,
          properties: props,
        };
        
        drawRef.current.value.add(feature);

        // Calculate the centroid of the polygon
        //const centroid = turf.centroid(feature);
        //const [lng, lat] = centroid.geometry.coordinates;
        const centroid = utils.calculateCentroid(zone.zone);

        textFeatures.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [centroid.lng, centroid.lat]
          },
          properties: {
            name: zone.name
          }
        });
      }

      if (source){
        const featuresCollection: FeatureCollection = {
					type: 'FeatureCollection',
					features: textFeatures
				}        
				source.setData(featuresCollection);
      }
    }

    if (zones){
      const zones_table: ZoneTableItem[] = zones?.map((x) => {
        return {
          ...x,
          is_edit: false,
        }
      });
      
      setZonesTable(zones_table);
    }

    handleSelectZone(selectedZoneId); //restore last selection (zone and mode)

  }, [mapInitialized, zones, drawRef.current.value, forceUpdateFeatures, handleSelectZone, selectedZoneId])

  useEffect(() => {
    // TO MAKE THE MAP APPEAR YOU MUST
    // ADD YOUR ACCESS TOKEN FROM
    // https://account.mapbox.com
    mapboxgl.accessToken = `${process.env.REACT_APP_MAPBOX_API_KEY}`;

    if (mapContainerRef.current && !mapInitialized)
    {
        mapRef.current.value = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: 'mapbox://styles/mapbox/streets-v12',
          center: [lng, lat],
          zoom: zoom
        });

        //const draw = new MapboxDraw({
        drawRef.current.value = new MapboxDraw({        
            displayControlsDefault: false,

            controls: {
                polygon: true,
                trash: true,

            },
            defaultMode: 'draw_polygon'
          });
          
        mapRef.current.value.addControl(drawRef.current.value);

        mapRef.current.value.on('draw.create', updateArea);
        mapRef.current.value.on('draw.update', updateArea);
        mapRef.current.value.on('draw.delete', deleteArea);
        mapRef.current.value.on('draw.selectionchange', selectArea);
        mapRef.current.value.on('move', () => {
          handleMapMove();
        });
        mapRef.current.value.on('load', () => {
          setMapInitialized(true);
          if (mapRef.current.value){
            
            mapRef?.current?.value?.addSource('polygon-text', {
              type: 'geojson',              
              data: {
                type: 'FeatureCollection',
                features: [{
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: []
                  },
                  properties: {
                    name: 'zone name' // Replace with desired text
                  }
                }]
              }
            });

            mapRef.current.value.addLayer({
              id: 'polygon-text',
              type: 'symbol',
              source: 'polygon-text',
              layout: {
                'text-field': '{name}',
                'text-font': ['Open Sans Regular'],
                'text-size': 12,
                'text-offset': [0, 0.5] // Adjust offset as needed
              }
            });
          }
        });
    }
  }, [mapContainerRef, lng, lat, zoom, mapInitialized, deleteArea, updateArea, selectArea]);

 
  return (
    <Box>
    <Stack direction="row" spacing={2}>
      <Box sx={{ flex: 2, height: '100%' }}>
        <TableContainer component={Paper}>
          <Table aria-label="zones">
            <TableHead>
              <TableRow>
                <TableCell>Zone Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {zonesTable?.map((zone) => (
                <TableRow
                  key={zone.zone_id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box sx={{ flex: 1 }}>
                        {zone.is_edit ?
                        <TextField
                            name="zone_name"
                            value={zoneName}
                            onChange={(e) => setZoneName(e.target.value)}
                            variant="standard"
                        />
                        :
                        <Link 
                          style={{ cursor: 'pointer' }}
                          underline={zone.zone_id === selectedZoneId ? "always" : "hover"} 
                          onClick = {(e) => handleSelectZone(zone.zone_id)}
                          >
                          {zone.zone_id === selectedZoneId &&
                            <ArrowRightOutlinedIcon />
                          }

                          {zone.name}
                        </Link>
                        }
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        {zone.is_edit ?
                          <IconButton
                              aria-label="open"
                              size="small"                                                                        
                              onClick={() => handleSaveZoneName(zone.zone_id)}
                          >
                              <SaveOutlinedIcon fontSize="inherit" />
                          </IconButton>
                          :                    
                          <Box>
                            <IconButton
                                aria-label="open"
                                size="small"
                                onClick={() => handleEditZoneName(zone.zone_id)}
                            >
                                <EditNoteOutlinedIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton
                                aria-label="open"
                                size="small"
                                onClick={() => handleDeleteZone(zone.zone_id, zone.name)}
                            >
                                <DeleteForeverOutlinedIcon fontSize="inherit" />
                            </IconButton>
                            </Box>
                        }
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ flex: 2, height: '100%' }}>
        <TableContainer component={Paper}>
          <Table aria-label="zones">
            <TableHead>
              <TableRow>
                <TableCell>{selectedZoneId ? `Users in ${selectedZoneName}` : 'Users'}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(selectedZoneId > 0) ?              
              selected_zone_users?.length ?
                selected_zone_users?.map((user) => (
                  <TableRow
                    key={user.user_id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ flex: 1 }}>
                          {user.full_name}
                        </Box>
                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row-reverse' }}>
                          <IconButton
                              aria-label="open"
                              size="small"
                              onClick={() => handleDeleteZoneUser(user.user_id, user.full_name)}
                          >
                              <DeleteForeverOutlinedIcon fontSize="inherit" />
                          </IconButton>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
                :
                <TableRow>
                    <TableCell component="th" scope="row">
                      Zone empty. Please add users.
                    </TableCell>
                </TableRow>
                :
                <TableRow>
                    <TableCell component="th" scope="row">
                      Zone not selected. Please select first the zone.
                    </TableCell>
                </TableRow>
              }
              {(selectedZoneId > 0) &&
              <TableRow>
                  <TableCell component="th" scope="row">
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box sx={{ flex: 1 }}>
                        <Select
                          id="allocate-active-shippers-select"
                          value={users_not_in_selected_zone ? selectedUserId : ''}
                          input={<Input />}
                          MenuProps={utils.MenuProps}
                          onChange={(e) => handleSelectUserId(e)}
                      >                                                
                          {users_not_in_selected_zone?.map((user, index) => (
                              <MenuItem key={user.user_id} value={user.user_id} >
                                  {user.name}
                              </MenuItem>
                          ))}
                        </Select>
                      </Box>
                      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row-reverse' }}>
                        <IconButton
                            aria-label="open"
                            size="small"                                                                        
                            onClick={(e) => handleSaveZoneUser()}
                        >
                            <AddCircleOutlineIcon fontSize="inherit" />
                        </IconButton>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                }


                
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box sx={{ flex: 8, height: '100%' }}>
        <div ref={mapContainerRef} id="map" style ={{ height: `${windowHeight-70}px`}} className="map-container"></div>
      </Box>
    </Stack>   

    
    </Box>
  );
};

export default Zones;