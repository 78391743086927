import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';
import * as ApiInterface from './ApiInterface';
import { http_axios_post, http_axios_get, http_axios_delete } from './ApiInterface';
import * as Models from "../models/Models";
import { ShopData, ShopCompany } from './FetchShops';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface EditShopState {
    isLoading: boolean;
    shopData: ShopData;
    shopCompanies: ShopCompany[];
    companies: Models.Company[];
    triggerLoad: boolean;
    triggerLoadState: boolean;    
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.


export interface RequestEditShopDataAction {
    type: 'REQUEST_EDIT_SHOP_DATA';
}
export interface ReceiveEditShopDataAction {
    type: 'RECEIVE_EDIT_SHOP_DATA';
    shopData: ShopData;
    shopCompanies: ShopCompany[];
    companies: Models.Company[];
    triggerLoadShopState: boolean;
}
export interface RequestSaveShopDataAction {
    type: 'REQUEST_SAVE_SHOP_DATA';
}
export interface ReceiveSaveShopDataAction {
    type: 'RECEIVE_SAVE_SHOP_DATA';
    triggerLoad: boolean;
}
export interface ResetTriggerLoadShop {
    type: 'RESET_TRIGGER_LOAD_SHOP';
}
export interface ResetTriggerLoadState {
    type: 'RESET_TRIGGER_LOAD_SHOP_STATE';
}
export interface SetTriggerLoadShop {
    type: 'SET_TRIGGER_LOAD_SHOP';
}
// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = ApiInterface.SetErrorAction |
    ApiInterface.SetInfoAction |
    RequestEditShopDataAction |
    ReceiveEditShopDataAction |
    RequestSaveShopDataAction |
    ReceiveSaveShopDataAction |
    ResetTriggerLoadShop |
    ResetTriggerLoadState |
    SetTriggerLoadShop;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    ...ApiInterface.actionCreators,
    requestEditData: (shop_id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {

            //https://stackoverflow.com/questions/35297446/best-es6-way-to-get-name-based-results-with-promise-all
            Promise.all([
                http_axios_get(`api/Shops/${shop_id}`)
                    .then(response =>  {
                        return response;
                    }),
                http_axios_get(`api/ShopsCompanies/${shop_id}`)
                    .then(response => {
                        return response;
                    }),
                http_axios_get('api/Lists/CompaniesGetByRole/0')
                    .then(list => {
                        return list;
                    }),
            ]).then(([shpData, shpCompanies, company]) => {
                const shopData = shpData as ShopData;
                const shopCompanies = shpCompanies as ShopCompany[];
                const companiesList = company as Models.Company[];

                console.log(shopCompanies);

                dispatch({
                    type: 'RECEIVE_EDIT_SHOP_DATA',
                    shopData: shopData,
                    shopCompanies: shopCompanies,
                    companies: companiesList,
                    triggerLoadShopState: true
                });
            })
            .catch(function (e) {
                    console.log(e);
                });

            
            dispatch({ type: 'REQUEST_EDIT_SHOP_DATA' });
        }
    },
    requestSaveData: (data: any, triggerLoad: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();        
        if (appState) {

            http_axios_post('api/Shops', data)
                .then(response => {
                    dispatch({ type: 'RECEIVE_SAVE_SHOP_DATA', triggerLoad: triggerLoad });
                })
                .catch(function (e) {
                    console.log(e);
                });
            
            dispatch({ type: 'REQUEST_SAVE_SHOP_DATA' });
        }
    },
    resetTriggerLoad: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'RESET_TRIGGER_LOAD_SHOP' });
        }
    },
    resetTriggerLoadState: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState) {
            dispatch({ type: 'RESET_TRIGGER_LOAD_SHOP_STATE' });
        }
    },
    requestDeleteShopCompany: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {        
        http_axios_delete('api/ShopsCompanies/' + id)
            .then(response => {
                dispatch({ type: 'SET_TRIGGER_LOAD_SHOP' });
            })
            .catch(function (e) {
                console.log(e);
            })
    },
    requestSaveShopCompany: (data: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        var url = ''
        if (appState) {

            url = 'api/ShopsCompanies';

            http_axios_post(url, data)
                .then(response => {
                    dispatch({ type: 'SET_TRIGGER_LOAD_SHOP' });
                })
                .catch(function (e) {
                    console.log(e);
                });
        }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: EditShopState = {
    isLoading: false,
    shopData: {
        id: 0,
        shop_id: 0,
        name: '',
        enabled: false,
        pickup_address_country: '',
        pickup_address_area: '',
        pickup_address_locality: '',
        pickup_address_number: '',
        pickup_address_street: '',
        pickup_contact_person: '',
        pickup_contact_phone: '',
    },
    shopCompanies: [],
    companies: [],
    triggerLoad: false,
    triggerLoadState: false,
};

export const reducer: Reducer<EditShopState> = (state: EditShopState | undefined, incomingAction: Action): EditShopState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_EDIT_SHOP_DATA':
            return {
                shopData: unloadedState.shopData,
                shopCompanies: unloadedState.shopCompanies,
                companies: unloadedState.companies,
                isLoading: true,
                triggerLoad: unloadedState.triggerLoad,
                triggerLoadState: unloadedState.triggerLoadState,
            };        
        case 'RECEIVE_EDIT_SHOP_DATA':
            return {
                shopData: action.shopData,
                shopCompanies: action.shopCompanies,
                companies: action.companies,
                isLoading: false,
                triggerLoad: state.triggerLoad,
                triggerLoadState: action.triggerLoadShopState,
            };
        case 'REQUEST_SAVE_SHOP_DATA':
            return state;
        case 'RECEIVE_SAVE_SHOP_DATA':
            return {
                shopData: state.shopData,
                shopCompanies: state.shopCompanies,
                companies: state.companies,
                isLoading: state.isLoading,
                triggerLoad: action.triggerLoad,
                triggerLoadState: state.triggerLoadState,
            };
        case 'RESET_TRIGGER_LOAD_SHOP':
            return {
                shopData: state.shopData,
                shopCompanies: state.shopCompanies,
                companies: state.companies,
                isLoading: state.isLoading,
                triggerLoad: false,
                triggerLoadState: state.triggerLoadState,
            };
        case 'RESET_TRIGGER_LOAD_SHOP_STATE':
            return {                
                shopData: state.shopData,
                shopCompanies: state.shopCompanies,
                companies: state.companies,
                isLoading: state.isLoading,
                triggerLoad: state.triggerLoad,
                triggerLoadState: false,
            };
        case 'SET_TRIGGER_LOAD_SHOP':
            return {
                shopData: state.shopData,
                shopCompanies: state.shopCompanies,
                companies: state.companies,
                isLoading: state.isLoading,
                triggerLoad: true,
                triggerLoadState: state.triggerLoadState,
            };

        default:
            return state;
    }
};
