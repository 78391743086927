import * as React from 'react';
import * as Models from "../models/Models";
import { Form, TextField2, TextAreaField, SelectField, MoneyField, DateTimeField } from './FormElements';

import { Box, Autocomplete, Button, Grid, } from '@mui/material';
import * as Validator from "../validator/Validator";
import { FormikValues, FormikHelpers, } from 'formik';
import _ from 'lodash';
import * as styled from './StyledComponents'
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetTransportRequestCompaniesQuery, useGetTransportRequestQuery, useGetTransportRequestSenderListQuery, useGetTransportRequestShopsQuery, useSaveTransportRequestMutation } from '../store/apiSlice';

export interface TransportRequestProps {
    transportId?: number;
    onCloseCallback?(): void;
}


interface TransportRequestLocalState {
    formData: {};
}


const TransportRequest : React.FC<TransportRequestProps> = (props) => {

    const params = useParams();   
    

    const navigate = useNavigate();

    const id =  props.transportId ? props.transportId : params.id ? parseInt(params.id) : 0;
    
    const [state, setState] = useState<TransportRequestLocalState>({
        formData: {}
    });
    
    
    
    const { data: request } = useGetTransportRequestQuery(id);
    const { data: companiesList } = useGetTransportRequestCompaniesQuery();
    const { data: shopsList } = useGetTransportRequestShopsQuery();
    const { data: senderList } = useGetTransportRequestSenderListQuery();

    const [ saveRequest ] = useSaveTransportRequestMutation();

    useEffect(() => {
        if (request){
            setState((prevState) => ({
                ...prevState,
                formData: JSON.parse(request.Details)
            }));
        }    
    }, [request]);   




    const handleSenderChanged = (event: any, newValue: Models.TransportRequestSender | null) => {
        if (newValue) {
            const details_json = JSON.parse(newValue.details);
            setState({ formData: {...state.formData, ...details_json }});            
        }
    }
    const onSubmit = (values: FormikValues, actions: FormikHelpers<FormikValues>) => {
        actions.setSubmitting(false);
        var s = JSON.stringify(values);
        const obj: Models.TransportRequest = {
            TransportId: id,
            Details: s
        }
        saveRequest(obj);
    }

    const handleClose = (e: any) => {
        e.preventDefault();
        if (props.onCloseCallback)
            props.onCloseCallback()
        else
            navigate("/searchtransportrequests");
    }

    const validate = async (values: FormikValues) :  Promise<Partial<Record<string | number, string>>> => {
        
        const errors: Partial<Record<string | number, string>> = {}; // Use Partial and Record for type safety

        if (!Validator.isText(values.sender_name)){
            errors.sender_name = 'Invalid value';
        }
        if (!Validator.isText(values.sender_addess_street)){
            errors.sender_addess_street = 'Invalid value';
        }
        if (!Validator.isText(values.sender_addess_number)){
            errors.sender_addess_number = 'Invalid value';
        }
        if (!Validator.isText(values.sender_addess_block)){
            errors.sender_addess_block = 'Invalid value';
        }
        if (!Validator.isText(values.sender_addess_stair)){
            errors.sender_addess_stair = 'Invalid value';
        }
        if (!Validator.isText(values.sender_addess_floor)){
            errors.sender_addess_floor = 'Invalid value';
        }
        if (!Validator.isText(values.sender_addess_apartment)){
            errors.sender_addess_apartment = 'Invalid value';
        }
        if (!Validator.isText(values.sender_addess_interphone)){
            errors.sender_addess_interphone = 'Invalid value';
        }
        if (!Validator.isText(values.sender_addess_locality)){
            errors.sender_addess_locality = 'Invalid value';
        }
        if (!Validator.isText(values.sender_addess_area)){
            errors.sender_addess_area = 'Invalid value';
        }
        if (!Validator.isText(values.sender_addess_country)){
            errors.sender_addess_country = 'Invalid value';
        }
        if (!Validator.isText(values.sender_addess_postalcode)){
            errors.sender_addess_postalcode = 'Invalid value';
        }
        if (!Validator.isText(values.sender_contact_person)){
            errors.sender_contact_person = 'Invalid value';
        }
        if (!Validator.isText(values.sender_contact_phone)){
            errors.sender_contact_phone = 'Invalid value';
        }
        if (!Validator.isText(values.receiver_name)){
            errors.receiver_name = 'Invalid value';
        }
        if (!Validator.isText(values.receiver_addess_street)){
            errors.sender_name = 'Invalid value';
        }
        if (!Validator.isText(values.receiver_addess_number)){
            errors.receiver_addess_number = 'Invalid value';
        }
        if (!Validator.isText(values.receiver_addess_block)){
            errors.receiver_addess_block = 'Invalid value';
        }
        if (!Validator.isText(values.receiver_addess_stair)){
            errors.receiver_addess_stair = 'Invalid value';
        }
        if (!Validator.isText(values.receiver_addess_floor)){
            errors.sender_name = 'Invalid value';
        }
        if (!Validator.isText(values.receiver_addess_apartment)){
            errors.receiver_addess_apartment = 'Invalid value';
        }
        if (!Validator.isText(values.receiver_addess_interphone)){
            errors.receiver_addess_interphone = 'Invalid value';
        }
        if (!Validator.isText(values.receiver_addess_locality)){
            errors.receiver_addess_locality = 'Invalid value';
        }
        if (!Validator.isText(values.receiver_addess_area)){
            errors.sender_name = 'Invalid value';
        }
        if (!Validator.isText(values.receiver_addess_country)){
            errors.receiver_addess_country = 'Invalid value';
        }
        if (!Validator.isText(values.receiver_addess_postalcode)){
            errors.sender_name = 'Invalid value';
        }
        if (!Validator.isText(values.receiver_contact_person)){
            errors.receiver_contact_person = 'Invalid value';
        }
        if (!Validator.isText(values.receiver_contact_phone)){
            errors.sender_name = 'Invalid value';
        }
        if (!Validator.isText(values.remarks)){
            errors.remarks = 'Invalid value';
        }

        if (!values.division){
            errors.division = 'Select value';
        }
        if (!values.payment_type){
            errors.payment_type = 'Select value';
        }
        if (!Validator.isNumeric(values.payment_amount)){
            errors.payment_amount = 'Invalid value';
        }
        if (!values.payment_amount_ccy){
            errors.payment_amount_ccy = 'Select value';
        }

        if (!Validator.isNumeric(values.delivery_price)){
            errors.delivery_price = 'Invalid value';
        }
        if (!values.delivery_price_ccy){
            errors.delivery_price_ccy = 'Select value';
        }

        if (!Validator.isDateTimeISO(values.pickup_preferred_date)){
            errors.pickup_preferred_date = 'Invalid value';
        }
        if (!Validator.isDateTimeISO(values.delivery_preferred_date)){
            errors.delivery_preferred_date = 'Invalid value';
        }

        if (!values.service_type){
            errors.service_type = 'Select value';
        }
        if (!values.package_type){
            errors.package_type = 'Select value';
        }

        if (!Validator.isNumeric(values.package_weight)){
            errors.package_weight = 'Invalid value';
        }
        if (!Validator.isNumeric(values.package_height)){
            errors.package_height = 'Invalid value';
        }
        if (!Validator.isNumeric(values.package_width)){
            errors.package_width = 'Invalid value';
        }
        if (!Validator.isNumeric(values.package_depth)){
            errors.package_depth = 'Invalid value';
        }
        if (!Validator.isNumeric(values.package_volume)){
            errors.package_volume = 'Invalid value';
        }

        if (!values.email) {
            errors.email = 'Required';
        }
        else if (!Validator.isEmail(values.email)){
            errors.email = 'Invalid email address';
        }

        if (!values.company_id){
            errors.company_id = 'Select value';
        }
        if (!values.shop_id){
            errors.shop_id = 'Select value';
        }

        return errors;
    };


    
    return (
        <div className="App">
            {_.isEmpty(state.formData) ? "" :
                <Form
                    enableReinitialize={true}
                    initialValues={state.formData}                        
                    validate={validate}                        
                    onSubmit={onSubmit}
                >

                    <Grid container spacing={5}>
                        <Grid item xs={6}>
                            <fieldset>
                                <legend>Sender</legend>
                                <Grid container spacing={5}>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            autoSelect
                                            onChange={(e, newData) => handleSenderChanged(e, newData)}
                                            options={senderList || []}
                                            getOptionLabel={(option: Models.TransportRequestSender) => option.sender_name}
                                            renderInput={(params) =>
                                                <TextField2 {...params} name="sender_predefined_list" label="Sender Predefined List" />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField2 name="sender_name" label="Name" />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField2 name="sender_addess_street" label="Street" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField2 name="sender_addess_number" label="Number" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField2 name="sender_addess_block" label="Block" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField2 name="sender_addess_stair" label="Stair" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField2 name="sender_addess_floor" label="Floor" />
                                    </Grid>

                                    <Grid item xs={2}>
                                        <TextField2 name="sender_addess_apartment" label="Apartment" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField2 name="sender_addess_interphone" label="Interphone" />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField2 name="sender_addess_locality" label="Locality" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField2 name="sender_addess_area" label="Area" />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <TextField2 name="sender_addess_country" label="Country" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField2 name="sender_addess_postalcode" label="Postal Code" />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField2 name="sender_contact_person" label="Contact Person" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField2 name="sender_contact_phone" label="Phone" />
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Grid>
                        <Grid item xs={6}>
                            <fieldset>
                                <legend>Receiver</legend>
                                <Grid container spacing={5}>
                                    <Grid item xs={12}>
                                        <TextField2 name="receiver_name" label="Name" />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField2 name="receiver_addess_street" label="Street" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField2 name="receiver_addess_number" label="Number" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField2 name="receiver_addess_block" label="Block" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField2 name="receiver_addess_stair" label="Stair" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField2 name="receiver_addess_floor" label="Floor" />
                                    </Grid>

                                    <Grid item xs={2}>
                                        <TextField2 name="receiver_addess_apartment" label="Apartment" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField2 name="receiver_addess_interphone" label="Interphone" />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField2 name="receiver_addess_locality" label="Locality" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField2 name="receiver_addess_area" label="Area" />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <TextField2 name="receiver_addess_country" label="Country" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <TextField2 name="receiver_addess_postalcode" label="Postal Code" />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField2 name="receiver_contact_person" label="Contact Person" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField2 name="receiver_contact_phone" label="Phone" />
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Grid>
                    
                        <Grid item xs={12}>
                            <fieldset>
                                <legend>Remarks</legend>
                                <TextAreaField name="remarks" />
                            </fieldset>
                        </Grid>
                    
                        <Grid item xs={12}>
                            <fieldset>
                                <legend>Additional Informations</legend>
                                <Grid id="bottom-row-details-new" container spacing={5}>
                                    <Grid item xs={2}>
                                        <SelectField 
                                            name='division'
                                            label='Division'
                                            required
                                            variant='standard'
                                            options={[
                                                {
                                                    "label": "Food",
                                                    "value": "Food"
                                                },
                                                {
                                                    "label": "Package",
                                                    "value": "Package"
                                                }
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <SelectField 
                                            name='payment_type'
                                            label='Payment Type'
                                            required
                                            variant='standard'
                                            options={[
                                                {
                                                    "label": "Card",
                                                    "value": "Card"
                                                },
                                                {
                                                    "label": "Ramburs",
                                                    "value": "Ramburs"
                                                }
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <MoneyField
                                            name='payment_amount'
                                            label='Payment Amount'
                                            required
                                            variant='standard'
                                            options={[
                                                {
                                                    "label": "RON",
                                                    "value": "RON"
                                                },
                                                {
                                                    "label": "EUR",
                                                    "value": "EUR"
                                                },
                                                {
                                                    "label": "USD",
                                                    "value": "USD"
                                                }
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <MoneyField
                                            name='delivery_price'
                                            label='Delivery Price'
                                            required
                                            variant='standard'
                                            options={[
                                                {
                                                    "label": "RON",
                                                    "value": "RON"
                                                },
                                                {
                                                    "label": "EUR",
                                                    "value": "EUR"
                                                },
                                                {
                                                    "label": "USD",
                                                    "value": "USD"
                                                }
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <DateTimeField
                                            name='pickup_preferred_date'
                                            label='Pickup Preferred Date'
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <DateTimeField
                                            name='delivery_preferred_date'
                                            label='Delivery Preferred Date'
                                            required
                                        />
                                    </Grid>

                                    {/* randul 2 */}
                                    <Grid item xs={2}>
                                        <SelectField 
                                            name='service_type'
                                            label='Service Type'
                                            required
                                            variant='standard'
                                            options={[
                                                {
                                                    "label": "Regular",
                                                    "value": "regular"
                                                },
                                                {
                                                    "label": "Express",
                                                    "value": "express"
                                                }
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <SelectField 
                                            name='package_type'
                                            label='Package Type'
                                            required
                                            variant='standard'
                                            options={[
                                                {
                                                    "label": "Normal",
                                                    "value": "Normal"
                                                },
                                                {
                                                    "label": "Fragile",
                                                    "value": "Fragile"
                                                }
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <TextField2
                                            name='package_weight'
                                            label='Weight (kg)'
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <TextField2
                                            name='package_height'
                                            label='Height (cm)'
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <TextField2
                                            name='package_width'
                                            label='Width (cm)'
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <TextField2
                                            name='package_depth'
                                            label='Depth (cm)'
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <TextField2
                                            name='package_volume'
                                            label='Volume (cm^3)'
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField2
                                            name='email'
                                            label='Email'
                                            required
                                        />
                                    </Grid>


                                    <Grid item xs={2}>
                                        <SelectField 
                                            name='company_id'
                                            label='Delivery Company'
                                            required
                                            variant='standard'
                                            options={
                                                companiesList?.map(x => {
                                                    return {label: x.name, value: x.company_id}
                                                })
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={2}>
                                        <SelectField 
                                            name='shop_id'
                                            label='Shop Name'
                                            required
                                            variant='standard'
                                            options={
                                                shopsList?.map(x => {
                                                    return {label: x.name, value: x.shop_id}
                                                })
                                            }
                                        />
                                    </Grid>
                                </Grid>

                                
                            </fieldset>
                        </Grid>
                    </Grid>
                    <Box sx={styled.buttonsBox}>
                        <Button variant="contained" color="primary" type="submit">
                            Save
                        </Button>   
                        <Button variant="contained"
                            onClick={handleClose}>
                            Close
                        </Button>
                    </Box>
                </Form>
            }
        </div>

    );    
}

export default TransportRequest;