import React from 'react';

const divStyleBadge: React.CSSProperties = {
    display: 'inline-block',
    padding: '0.25em 0.4em',
    fontSize: '75%',
    fontWeight: 'bolder',
    lineHeight: '1',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'baseline',
    borderRadius: '0.25rem'
    //transition: 'color 0.15s ease -in -out, background - color 0.15s ease -in -out, border - color 0.15s ease -in -out, box - shadow 0.15s ease -in -out',
};


interface StatusBadgeProps {    
    status: number;
    status_desc: string;
    fontSize?: string;
  }
  
  const StatusBadge: React.FC<StatusBadgeProps> = (props) => {

    const getStatusColor = (status: number) : string =>
    {
        if (status === 1) return "#fff";
        if (status === 2) return "#212529";
        if (status === 3) return "#fff";
        if (status === 4) return "#fff";
        if (status === 5) return "#fff";
        if (status === 6) return "#fff";
        if (status === 7) return "#fff";
        if (status === 8) return "#fff";
        if (status === 9) return "#fff";
        if (status === 10) return "#000000";
        if (status === 11) return "#000000";
        if (status === 12) return "#000000";
        if (status === 13) return "#000000";
        return "#000000";
    }

    const getStatusBackgroudColor = (status: number) =>
    {
        if (status === 1) return "#343a40";
        if (status === 2) return "#ffc107";
        if (status === 3) return "#007bff";
        if (status === 4) return "#6c757d";
        if (status === 5) return "#17a2b8";
        if (status === 6) return "#dc3545";
        if (status === 7) return "#28a745";
        if (status === 8) return "#28a745";
        if (status === 9) return "#28a745";
        if (status === 10) return "#ffffff";
        if (status === 11) return "#ffffff";
        if (status === 12) return "#ffffff";
        if (status === 13) return "#ffffff";
        return "#ffffff";
    }
    
    const color_1 = getStatusColor(props.status);
    const color_2 = getStatusBackgroudColor(props.status);

    return (
        <div style={Object.assign({}, divStyleBadge, { color: color_1, backgroundColor: color_2, fontSize: props.fontSize || '75%' })}>
            {props.status_desc}
        </div>
    );
  };
  
  export default StatusBadge;
  