import * as React from 'react';
import { ChangeEvent } from 'react';
import { connect } from 'react-redux'
import {
    Grid,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';

import { ApplicationState } from '../store';
import * as UploadStore from '../store/Upload';

// At runtime, Redux will merge together...

interface UploadFileStateProps {
    binary: string;
    file: File;
}

type UploadProps =
    UploadStore.UploadState // ... state we've requested from the Redux store
    & typeof UploadStore.actionCreators; // ... plus action creators we've requested
    

export class Upload extends React.PureComponent<UploadProps, UploadFileStateProps> {

    constructor(props: Readonly<UploadProps>) {
        super(props);        
        

        // This binding is necessary to make "this" work in the callback  
        this.handleUpload = this.handleUpload.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);

        this.handleImport = this.handleImport.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        
    }
    
    // This will handle the submit form event.  
    private async handleUpload(event: any) {
        event.preventDefault();
    }

    private manageUploadedFile(binary: string, file: File) {        
        //console.log(`The file size is ${binary.length}`);
        //console.log(`The file name is ${file.name}`);
        this.props.upload(binary, file);
    }

    private getFileFromInput(file: File): Promise<any> {
        return new Promise(function (resolve, reject) {
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onload = function () { resolve(reader.result); };
            reader.readAsBinaryString(file); // here the file can be read in different way Text, DataUrl, ArrayBuffer
        });
    }

    // This will handle Cancel button click event.  
    private handleFileChange(event: ChangeEvent<HTMLInputElement>) {
        

        if (event != null &&
            event.target != null &&
            event.target.files != null) {

            var file = event?.target?.files[0];
            this.getFileFromInput(file)
                .then((binary) => {
                    this.manageUploadedFile(binary, file);
                }).catch(function (reason) {
                    console.log(`Error during upload ${reason}`);
                    event.target.value = ''; // to allow upload of same file if error occurs
                });
        }
    }

    private handleImport(e: any) {
        e.preventDefault();
        this.props.import();
    }

    private handleDelete(e: any) {
        e.preventDefault();
        this.props.delete();
    }

    // This method is called when the component is first added to the document
    componentDidMount() {
        //console.log(this.props.expeditions);
        this.props.load();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {

        //console.log(this.props.expeditions);

        if (this.props.triggerLoad) {
            this.props.resetTriggerLoad();
            this.props.load();
        }        
    }

    

    public render() {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <input accept=".csv" id="file" type="file"
                        onChange={this.handleFileChange} />
                </Grid>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="right">Id</TableCell>
                                    <TableCell align="right">No</TableCell>
                                    <TableCell align="right">Sender Name</TableCell>
                                    <TableCell align="right">Sender Contact Person</TableCell>
                                    <TableCell align="right">Receiver Name</TableCell>
                                    <TableCell align="right">Receiver Contact Person</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.expeditions?.map((row: UploadStore.CsvExpedition) => (
                                    <TableRow key={row.id}>
                                        <TableCell align="right">{row.id}</TableCell>
                                        <TableCell align="right">{row.no}</TableCell>
                                        <TableCell align="right">{row.sender_name}</TableCell>
                                        <TableCell align="right">{row.sender_contact_person}</TableCell>
                                        <TableCell align="right">{row.receiver_name}</TableCell>
                                        <TableCell align="right">{row.receiver_contact_person}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained"
                        onClick={this.handleImport}>
                        Import
                    </Button>
                    <Button variant="contained"
                        onClick={this.handleDelete}>
                        Delete
                    </Button>
                    
                </Grid>
            </Grid>
        );
    }
   
}

export default connect(
    (state: ApplicationState) => state.upload, // Selects which state properties are merged into the component's props
    UploadStore.actionCreators // Selects which action creators are merged into the component's props
)(Upload as any);