import * as React from 'react';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import * as Validator from "../validator/Validator";
import { Dialog, DialogTitle, DialogContent, } from './StyledComponents'
import { Grid, Button, Input, TextField, InputLabel, Select, MenuItem, FormControl, Box, Tooltip, IconButton, Stack, Pagination, DialogActions } from '@mui/material';



import { 
    Info as InfoIcon,
    LaunchOutlined as LaunchOutlinedIcon,
    SettingsApplicationsOutlined as SettingsApplicationsOutlinedIcon,
    MyLocationOutlined as MyLocationOutlinedIcon
} from '@mui/icons-material';
import * as utils from '../store/Utils';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { RootState, useAppDispatch } from '../store/configureStore';
import * as FetchTransportRequestsStore from '../store/FetchTransportRequests';
import * as Navigation from "../navigation/Navigation";
import { useChangeState } from '../utils/useChangeState';
import { useMemo, useState } from 'react';
import { shallowCompare } from '../utils/Utils';
import { useGetListsCompaniesByRoleQuery, useGetListsQuery, useGetListsTransportRequestsStatusQuery, useGetListsWithDefaultValueQuery, useGetTransportRequestsQuery } from '../store/apiSlice';
import { skipToken } from '@reduxjs/toolkit/query';
import { setError } from '../store/ApiInterface2';
import TransportRequestSettings from './TransportRequestSettings';
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import StatusBadge from './StatusBadge';




export const SearchTransportRequests : React.FC = (props) => {
    

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const storeState = useSelector((state: RootState) => state.fetchTransportRequests)
    
    const [state, setState] = useState<FetchTransportRequestsStore.FormData>({
        ...storeState.formData,
    });
    
    const changeState = useChangeState(state, setState);

    const [searchState, setSearchState] = useState<FetchTransportRequestsStore.FormData>({
        ...storeState.formData
    });

    const { data: pageSizeList } = useGetListsQuery('3');
    const { data: paymentTypeList } = useGetListsWithDefaultValueQuery({list_id: '4', default_value: 'ALL', default_description: 'ALL'});
    const { data: companiesList } = useGetListsCompaniesByRoleQuery({ default_value: '0', default_description: 'ALL'});
    const { data: statusList } = useGetListsTransportRequestsStatusQuery();
    

    const { data, refetch } = useGetTransportRequestsQuery((paymentTypeList != null && 
                                                            pageSizeList != null &&
                                                            companiesList != null &&
                                                            statusList != null
                                                            ) ? searchState : skipToken) //asteptam sa vina lista yesNo si pageSize apoi apelam cautarea


    const handleSearch = (event: any) => {
        event.preventDefault();
        if (handleValidation()){
            if (shallowCompare(state, searchState)){
                //cazul in care apasa pe "search" fara sa schimbe vreun parametru - fortam refresh
                refetch();
            }
            else {
                setSearchState({ ...state, PageNumber: 0 })
            }
        }
    }

    const handleChangePageSize = (event: any) => {
        if (handleValidation()){
            setState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  })
            setSearchState({ ...state, PageSize: Number(event.target.value), PageNumber: 0  }) //nu avem optiunea de a modifica state-ul si a astepta cu "await" sa fie efectuata modificarea; asa ca apelam schimbarea de pagina in cele 2 state-uri explicit (asta ca sa fie si efectuata apelarea API-ului si sa ramana si in state-ul responsabil de UI)
        }
    }

    const handleStatusChanged = (event: any) => {
        const status_string = event.target.value.join(","); // la apelarea API-ului prin GET nu se poate trimite un array de numere => il "serializam" concatenand statusurile intr-un string urmand ca in backend sa il refacem array de numere
        setState((prevState) => ({ ...prevState, status: event.target.value as Array<number>, status_string: status_string }));
    }

    const handleValidation = () => {
        let result = true;
        let err = '';

        if (!Validator.isDateTimeISO(state.start_date)) {
            err += "Start Date is invalid!\n";
            result = false;
        }

        if (!Validator.isDateTimeISO(state.end_date)) {
            err += "End Date is invalid!\n";
            result = false;
        }

        if (state.transport_id !== "" && !Validator.isNumeric(state.transport_id)) {
            err += "Transport Id must be numeric!\n";
            result = false;
        }
        if (state.transport_id !== "" && state.transport_id.length > 50) {
            err += "Transport Id should not exceed 50 characters!\n";
            result = false;
        }

        if (state.awb !== "" && !Validator.isNumeric(state.awb)) {
            err += "Awb must be numeric!\n";
            result = false;
        }
        if (state.awb !== "" && state.awb.length > 50) {
            err += "Awb should not exceed 50 characters!\n";
            result = false;
        }

        if (state.ext_ref !== "" && !Validator.isText(state.ext_ref)) {
            err += "External Reference contain illegal character!\n";
            result = false;
        }
        if (state.ext_ref !== "" && state.ext_ref.length > 50) {
            err += "External Reference should not exceed 50 characters!\n";
            result = false;
        }

        if (state.tracking_number !== "" && !Validator.isAlphaNumeric(state.tracking_number)) {
            err += "Tracking Number must be alpha numeric!\n";
            result = false;
        }
        if (state.tracking_number !== "" && state.tracking_number.length > 50) {
            err += "Tracking Number should not exceed 50 characters!\n";
            result = false;
        }

        if (state.sender_name !== "" && !Validator.isText(state.sender_name)) {
            err += "Sender Name contain illegal character!\n";
            result = false;
        }
        if (state.sender_name !== "" && state.sender_name.length > 50) {
            err += "Sender Name should not exceed 50 characters!\n";
            result = false;
        }

        if (!result) {
            dispatch(setError(err))
        }
        
        return result;
    }

    const handleCloseSettingsModal = () => {
        setState((prevState) => ({ ...prevState, show_settings_dialog: false, show_settings_dialog_transport_id: 0 }));
    }

    const handleOpenSettingsModal = (transport_id: number) => {
        setState((prevState) => ({ ...prevState, show_settings_dialog: true, show_settings_dialog_transport_id: transport_id }));
    }    

    const handleStartDateChanged = (new_value: Date | dayjs.Dayjs | null) => {
        const current_date = dayjs(state.start_date);
        const new_date = dayjs(new_value);
        let new_date_formated = new_date.format('YYYY-MM-DDTHH:mm');        

        if (current_date.year() !== new_date.year() ||
            current_date.month() !== new_date.month() ||
            current_date.day() !== new_date.day()){
            new_date_formated = new_date.format('YYYY-MM-DD') + 'T00:00';
        }

        setState((prevState) => ({ ...prevState, start_date: new_date_formated }));
    }

    
    const handleEndDateChanged = (new_value: Date | dayjs.Dayjs | null) => {
        const current_date = dayjs(state.end_date);
        const new_date = dayjs(new_value);
        let new_date_formated = new_date.format('YYYY-MM-DDTHH:mm');        

        if (current_date.year() !== new_date.year() ||
            current_date.month() !== new_date.month() ||
            current_date.day() !== new_date.day()){
            new_date_formated = new_date.format('YYYY-MM-DD') + 'T23:59';
        }

        setState((prevState) => ({ ...prevState, end_date: new_date_formated }));
    }

    const renderSearchBox = () => {        
        return (
                <Grid container spacing={5}>
                    <Grid item xs={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDateTimePicker 
                                slotProps={{ textField: { variant: "standard", label: "Start Date" } }}
                                ampm={false}
                                format={'YYYY-MM-DD HH:mm'}
                                value={dayjs(state.start_date)} 
                                onChange={(new_value) => handleStartDateChanged(new_value)}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            name="transport_id"
                            label="Transport Id"
                            value={state.transport_id}
                            onChange={changeState}
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            name="tracking_number"
                            label="Tracking Number"
                            value={state.tracking_number}
                            onChange={changeState}
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            name="sender_name"
                            label="Sender Name"
                            value={state.sender_name}
                            onChange={changeState}
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl sx={{ margin: 1, minWidth: "120px", maxWidth: "200px" }}>
                            <InputLabel id="label-status">Status</InputLabel>
                            <Select
                                labelId="label-status"
                                id="status"
                                name="status"
                                multiple
                                value={statusList ? state.status : ''}
                                input={<Input />}
                                MenuProps={utils.MenuProps}
                                onChange={handleStatusChanged}
                            >
                                {statusList?.map((status, index) => (
                                    <MenuItem key={status.status} value={status.status} >
                                        {status.description}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel id="label-payment-type">Payment Type</InputLabel>
                        <Select
                            labelId="label-payment-type"
                            id="payment_type"
                            name="payment_type"
                            value={paymentTypeList ? state.payment_type : ''}
                            input={<Input />}
                            MenuProps={utils.MenuProps}
                            onChange={(e) => changeState(e)}
                        >
                            {paymentTypeList?.map(payment =>
                                <MenuItem key={payment.Value} value={payment.Value}>{payment.Description}</MenuItem>
                            )}
                        </Select>
                    </Grid>

                
                    <Grid item xs={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDateTimePicker 
                                slotProps={{ textField: { variant: "standard", label: "End Date" } }}
                                ampm={false}
                                format={'YYYY-MM-DD HH:mm'}
                                value={dayjs(state.end_date)}
                                onChange={(new_value) => handleEndDateChanged(new_value)}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            name="awb"
                            label="Awb"
                            value={state.awb}
                            onChange={changeState}
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            name="ext_ref"
                            label="Ext Reference"
                            value={state.ext_ref}
                            onChange={changeState}
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            name="user_name"
                            label="Created by user"
                            value={state.user_name}
                            onChange={changeState}
                            variant="standard"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel id="label-company">Company</InputLabel>
                        <Select
                            labelId="label-company"
                            id="company_id"
                            name="company_id"
                            value={companiesList ? state.company_id : ''}
                            input={<Input />}
                            MenuProps={utils.MenuProps}
                            onChange={(e) => changeState(e)}
                        >
                            {companiesList?.map(company =>
                                <MenuItem key={company.company_id} value={company.company_id}>{company.name}</MenuItem>
                            )}
                        </Select>
                    </Grid>
                    <Grid item xs={2}>
                        <InputLabel id="label-page-size">Page size</InputLabel>
                        <Select
                            labelId="label-page-size"
                            id="PageSize"
                            name="PageSize"
                            value={pageSizeList ? state.PageSize : ''}
                            input={<Input />}
                            MenuProps={utils.MenuProps}
                            onChange={handleChangePageSize}
                        >
                            {pageSizeList?.map(pageSize =>
                                <MenuItem key={pageSize.Value} value={pageSize.Value}>{pageSize.Description}</MenuItem>
                            )}
                        </Select>
                    </Grid>

                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={handleSearch}>
                            Search
                        </Button>
                    </Grid>
                </Grid>
        );
    }    
    
    const renderSearchResult = useMemo(() => {

        const handleNavigation = (pageNumber: number) => {            
            setSearchState((prevState) => ({ ...prevState, PageNumber: pageNumber }));
        }

        const handleOpenRequest = (id: string) => {
            navigate("/transportrequest/edit/" + id);
        }

        const columns: GridColDef[] = [
            { 
                field: 'transport_id', 
                headerName: 'Transport Id', 
                display: 'flex', 
                flex: 1,
                renderCell: (params: GridCellParams) => (
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box>{params.row.transport_id}</Box>
                        <Box>
                            <Tooltip
                                title={
                                    <div style={{ whiteSpace: 'pre-line' }}>
                                        {params.row.remarks}
                                    </div>
                                }
                            >
                                <InfoIcon/>
                            </Tooltip>
                        </Box>
                    </Box>
                ),
            },
            { field: 'awb', headerName: 'AWB', display: 'flex', flex: 0.75 },
            {
                field: "ins_date",
                headerName: "Request Date",
                display: 'flex',
                flex: 1,
                type: "string",
                renderCell: (params) => moment(params.row.ins_date).format('DD.MM.YYYY HH:mm:ss')
            },
            { 
                field: 'sender', 
                headerName: 'Sender',
                display: 'flex',
                flex: 1.5,
                renderCell: (params: GridCellParams) => (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box>{params.row.sender_name}</Box>
                        <Box>{`Address: ${params.row.sender_address}`}</Box>
                    </Box>
                ),
            },
            { 
                field: 'receiver', 
                headerName: 'Receiver',
                display: 'flex',
                flex: 1.5,
                renderCell: (params: GridCellParams) => (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box>{params.row.receiver_name}</Box>
                        <Box>{`Address: ${params.row.receiver_address}`}</Box>
                    </Box>
                ),
            },
            { 
                field: 'allocation', 
                headerName: 'Allocation',
                display: 'flex',
                flex: 1,
                renderCell: (params: GridCellParams) => (                    
                    <div style={{ whiteSpace: 'pre-line' }}>
                        {params.row.allocation}
                    </div>
                ),
            },
            { 
                field: 'status', 
                headerName: 'Status', 
                display: 'flex', 
                flex: 1.2,
                align: 'center',
                renderCell: (params: GridCellParams) => (                    
                    <StatusBadge status={params.row.status} status_desc={params.row.status_desc} fontSize='100%'/>
                ),

            },
            { field: 'payment_type', headerName: 'Payment Type', display: 'flex', flex: 1 },
            {
                field: 'view',
                headerName: '',
                display: 'flex',
                width: 200,
                renderCell: (params: GridCellParams) => (
                    <Box>
                        <Tooltip title="Open order">
                            <IconButton
                                aria-label="open"
                                size="large"                                                                        
                                onClick={() => handleOpenRequest(params.row.transport_id)}
                            >
                                <LaunchOutlinedIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Settings">
                            <IconButton
                                aria-label="open"
                                size="large"
                                onClick={() => handleOpenSettingsModal(params.row.transport_id)}
                            >
                                <SettingsApplicationsOutlinedIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Track">
                            <IconButton
                                aria-label="open"
                                size="large"
                                component="a" href={`/awb/${params.row.tracking_number}`} target="_blank"
                            >
                                <MyLocationOutlinedIcon fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                ),
            },        
        ];

        return (
            data &&
                <fieldset>
                    <legend>Search results</legend>
                    <Box sx={{ height: '100%', width: '100%', display:'flex', flexDirection: 'column' }}>
                        <DataGrid
                            rows={data.Data ?? []}
                            columns={columns}
                            getRowId={(r) => r.transport_id}
                            getRowHeight={() => 'auto'}
                            rowCount={data.RowCount}
                            pagination
                            pageSizeOptions={[searchState.PageSize]}
                            paginationMode="server"
                            paginationModel= {{ pageSize: searchState.PageSize, page: searchState.PageNumber }}
                            onPaginationModelChange={(e) => handleNavigation(e.page)}
                        />
                    </Box>
                    <Stack spacing={2} justifyContent="center" alignItems="center">
                        <Pagination 
                            count={Navigation.pageCount(data.Last, data.Current)} 
                            page={Navigation.currentPage(data.Current)} 
                            onChange={(event, page) => handleNavigation(page - 1)} 
                            disabled={Navigation.isNavigationDisabled(data.Last, data.Current)} 
                            showFirstButton
                            showLastButton
                        />
                    </Stack>
                </fieldset>
        );
    }, [data, navigate, searchState.PageNumber, searchState.PageSize]);

    return (
        <React.Fragment>
            <Dialog fullScreen onClose={handleCloseSettingsModal} aria-labelledby="customized-dialog-title-details" open={state.show_settings_dialog}>
                <DialogTitle id="customized-dialog-title-details" onClose={handleCloseSettingsModal}>
                    Details
                </DialogTitle>
                <DialogContent dividers>
                    {state.show_settings_dialog_transport_id && 
                    <TransportRequestSettings {...{
                        transport_id: state.show_settings_dialog_transport_id,
                        status_list: statusList!,
                        onCloseCallback: handleCloseSettingsModal
                    }}>
                    </TransportRequestSettings>
                    }
                    
                </DialogContent>
                <DialogActions>                    
                    <Button color="primary" onClick={handleCloseSettingsModal}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <h1 id="tabelLabel">Transport Requests</h1>
            {renderSearchBox()}
            {renderSearchResult}
        </React.Fragment>
    );
}

export default SearchTransportRequests;
