import * as React from 'react';
import * as Models from "../models/Models";
import * as Validator from "../validator/Validator";
import { Box, Select, Grid, TextField, Button, MenuItem, Input, InputLabel, Switch, FormControlLabel, Checkbox } from '@mui/material';
import * as utils from '../store/Utils';
import * as styled from './StyledComponents'
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useGetListsCompaniesByRoleQuery, useGetListsShopsByRoleQuery, useGetUserQuery, useSaveUserChangePasswordMutation, useSaveUserMutation } from '../store/apiSlice';
import { useChangeState } from '../utils/useChangeState';
import { setError } from '../store/ApiInterface2';
import { useAppDispatch } from '../store/configureStore';


const EditUser : React.FC = (props) => {
    const params = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const id =  params.id ? parseInt(params.id) : 0;   

    const [state, setState] = useState<Models.UserData>({
        ...Models.getUserDataDefaultValues()
    });
    
    const changeState = useChangeState(state, setState);
    
    const { data: companiesList } = useGetListsCompaniesByRoleQuery({ default_value: '0', default_description: 'Select company'});
    const { data: shopsList } = useGetListsShopsByRoleQuery({ default_value: '0', default_description: 'Select shop'});
    const { data } = useGetUserQuery(id);
    const [ saveData ] = useSaveUserMutation();
    const [ changePassword ] = useSaveUserChangePasswordMutation();

    
    useEffect(() => {
        if (data){
            setState((prevState) => ({
                ...data
            }));
        }    
    }, [data]);   


    // This will handle the submit form event.  
    const handleSave = (event: any) => {
        event.preventDefault();
        if (handleValidation()) {            
            saveData(state);
        }
    }
    // This will handle Cancel button click event.  
    const handleClose = (e: any) => {
        e.preventDefault();
        navigate("/fetchusers");
    }
    const handleChangePassword = (event: any) => {
        event.preventDefault();
        changePassword(state);
    }    

    const handleCheckRole = (event: any) => {
        var i;
        let roles = [...state.Roles];
        for (i = 0; i < roles.length; i++)
        {
            let role = { ...roles[i] };
            if (role.Value === event.target.value) {
                role.Selected = event.target.checked;
                roles[i] = role;
                break;
            }
        }
        setState({ ...state, Roles: roles });
    }

    const handleValidation = () => {
        let result = true;
        let err = '';

        var Type = state.Type;
        var Company = state.Company;
        var Shop = state.Shop;

        var FirstName = state.FirstName;
        var LastName = state.LastName;
        var Email = state.Email;
        var PhoneNumber = state.PhoneNumber;

        if (Type === "") {
            err += "Select user type!\n";
            result = false;
        }
        if (Type === "COMPANY") {

            if (Company === 0) {
                err += "Select user company!\n";
                result = false;
            }

            if (Shop !== 0) {
                err += "For company users you can not select shops!\n";
                result = false;
            }
        }

        if (Type === "SHOP") {

            if (Shop === 0) {
                err += "Select user shop!\n";
                result = false;
            }

            if (Company !== 0) {
                err += "For shops users you can not select company!\n";
                result = false;
            }
        }

        if (!Validator.isAlphaNumericAndSpaceUnderscore(FirstName)) {
            err += "First Name should be alpha numeric or underscore!\n";
            result = false;
        }
        if (FirstName.length <= 0 || FirstName.length > 50) {
            err += "First Name should be between 1 and 50 characters!\n";
            result = false;
        }

        if (!Validator.isAlphaNumericAndSpaceUnderscore(LastName)) {
            err += "Last Name should be alpha numeric or underscore!\n";
            result = false;
        }
        if (LastName.length <= 0 || LastName.length > 50) {
            err += "Last Name should be between 1 and 50 characters!\n";
            result = false;
        }

        if (!Validator.isEmail(Email)) {
            err += "Email is not valid!\n";
            result = false;
        }
        if (Email.length <= 0 || Email.length > 100) {
            err += "Email should be between 1 and 100 characters!\n";
            result = false;
        }

        if (!Validator.isNumeric(PhoneNumber)) {
            err += "Phone Number is not valid!\n";
            result = false;
        }
        if (PhoneNumber.length <= 0 || PhoneNumber.length > 20) {
            err += "Phone Number should be between 1 and 20 characters!\n";
            result = false;
        }

        if (!result) {
            console.log(err);
            dispatch(setError(err));
        }

        return result;
    }

    
    return <div>
        <h3>User management</h3>
        <hr />
        <div>
            <fieldset>
                <legend>Details</legend>
                <form name="formEditUser" id="formEditUser" onSubmit={handleSave}>
                    <Grid container spacing={5}>
                        <Grid item xs={5}>
                            <Grid container spacing={5}>
                                {state.UserId === 0 ? null :
                                    <Grid item xs={12}>
                                        <TextField
                                            id="UserName"
                                            name="UserName"
                                            label="User Name"
                                            variant="standard"
                                            value={state.UserName}
                                            disabled
                                        />
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={state.UserEnabled}
                                                    onChange={e => changeState(e)}
                                                    name="UserEnabled"
                                                    color="primary"
                                                />
                                            }
                                            labelPlacement="end"
                                            label="Enabled"
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <TextField
                                        id="Email"
                                        name="Email"
                                        label="Email"
                                        variant="standard"
                                        value={state.Email}
                                        onChange={changeState}
                                        required
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={state.EmailConfirmed}
                                                onChange={e => changeState(e)}
                                                name="EmailConfirmed"
                                                color="primary"
                                            />
                                        }
                                        labelPlacement="end"
                                        label="Email Confirmed"
                                    />
                                </Grid>
                                {state.UserId > 0 ? null :
                                    <>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="Password1"
                                                name="Password1"
                                                label="Password"
                                                type="password"
                                                variant="standard"
                                                onChange={changeState}
                                                required />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                id="Password2"
                                                name="Password2"
                                                label="Confirm Password"
                                                type="password"
                                                variant="standard"
                                                onChange={changeState}
                                                required />
                                        </Grid>
                                    </>

                                }
                                <Grid item xs={4}>

                                    <InputLabel shrink id="labelType">
                                        Type
                                    </InputLabel>
                                    <Select
                                        name="Type"
                                        labelId="labelType"
                                        value={state.Type}
                                        input={<Input />}
                                        displayEmpty={true}
                                        notched={undefined} //trebuie si notched setat asa pt ca altfel da waring in consola  Received `true` for a non-boolean attribute `notched`.
                                        MenuProps={utils.MenuProps}
                                        onChange={e => changeState(e)}                                        
                                        
                                    >
                                        <MenuItem value="">Select type</MenuItem>
                                        <MenuItem value="COMPANY">Company</MenuItem>
                                        <MenuItem value="SHOP">Shop</MenuItem>
                                    </Select>
                                </Grid>

                                <Grid item xs={4}>
                                    <InputLabel shrink id="labelCompany">
                                        Company
                                    </InputLabel>
                                    <Select
                                        name="Company"
                                        labelId="labelCompany"
                                        value={companiesList ? state.Company : ''}
                                        input={<Input />}
                                        MenuProps={utils.MenuProps}
                                        onChange={e => changeState(e)}
                                    >
                                        {companiesList?.map(o =>
                                            <MenuItem key={o.company_id} value={o.company_id}>{o.name}</MenuItem>
                                        )}
                                    </Select>
                                </Grid>

                                <Grid item xs={4}>
                                    <InputLabel shrink id="labelShop">
                                        Shop
                                    </InputLabel>
                                    <Select
                                        name="Shop"
                                        labelId="labelShop"
                                        value={shopsList ? state.Shop : ''}
                                        input={<Input />}
                                        MenuProps={utils.MenuProps}
                                        onChange={e => changeState(e)}
                                    >
                                        {shopsList?.map(o =>
                                            <MenuItem key={o.shop_id} value={o.shop_id}>{o.name}</MenuItem>
                                        )}
                                    </Select>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid container spacing={5}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="FirstName"
                                        name="FirstName"
                                        label="First Name"
                                        variant="standard"
                                        value={state.FirstName}
                                        onChange={changeState}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="LastName"
                                        name="LastName"
                                        label="Last Name"
                                        variant="standard"
                                        value={state.LastName}
                                        onChange={changeState}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="PhoneNumber"
                                        name="PhoneNumber"
                                        label="Phone Number"
                                        variant="standard"
                                        value={state.PhoneNumber}
                                        onChange={changeState}
                                        required
                                    />

                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={state.PhoneNumberConfirmed}
                                                onChange={e => changeState(e)}
                                                name="PhoneNumberConfirmed"
                                                color="primary"
                                            />
                                        }
                                        labelPlacement="end"
                                        label="Phone Number Confirmed"
                                    />
                                </Grid>                                    
                            </Grid>
                        </Grid>
                        <Grid item xs={2}>
                            <fieldset>
                                <legend>User roles</legend>
                                <Grid container spacing={1}>                                    
                                {
                                    state.Roles.map(item => (
                                        <Grid item xs={12} key={item.Value}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={item.Selected}
                                                        value={item.Value}
                                                        key={item.Value}
                                                        onChange={handleCheckRole}
                                                        name="Roles"
                                                        color="primary"
                                                    />
                                                }
                                                label={item.Value}
                                            />
                                        </Grid>
                                    ))
                                    }
                                </Grid>
                            </fieldset>
                        </Grid>                    
                        <Grid item xs={12}>
                            <Box sx={styled.buttonsBox}>
                                <Button variant="contained" color="primary" type="submit">
                                    Save
                                </Button>                                
                                <Button variant="contained" color="primary" onClick={handleClose}>
                                    Close
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </fieldset>
            {state.UserId === 0 ? null :
                <fieldset>
                    <legend>Change password</legend>
                    <form name="formhandleChangePassword" onSubmit={handleChangePassword} >

                        <Grid container spacing={5}>
                            <Grid item xs={4}>
                                <TextField
                                    id="Password1"
                                    name="Password1"
                                    label="Password"
                                    type="password"
                                    variant="standard"
                                    onChange={e => changeState(e)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={8}>
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    id="Password2"
                                    name="Password2"
                                    label="Confirm Password"
                                    type="password"
                                    variant="standard"
                                    onChange={e => changeState(e)}
                                    required
                                />
                            </Grid>
                            <Grid item xs={8}>
                            </Grid>

                            <Grid item xs={4}>
                                <Button variant="contained" color="primary" type="submit">
                                    Change password
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </fieldset>
            }
        </div>
    </div>;    
}    


export default EditUser;



